import Joi from "joi";

export const update = Joi.object({
    id: Joi.string()
        .regex(/^[0-9a-fA-F]{24}$/)
        .required(),
    notes: Joi.string()
        .max(1000)
        .optional()
        .allow(null, ""),
    closed: Joi.boolean()
        .optional()
});

