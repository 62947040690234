<template>
    <Page icon="mdi-lifebuoy" :title="(helprequest) ? helprequest.counter : ''" :error="error" :progress="progress">
        <template v-if="helprequest">
        <ActionBar :updateTime="updateTime">
            <template v-slot>
                <v-btn color="primary" v-if="!helprequest.user" @click="save()" :loading="saveProgress" :disabled="saveProgress || dangerProgress">
                    <v-icon left>mdi-shield-check-outline</v-icon>{{ $t('accept') }}
                </v-btn>
                <template v-else>
                    <v-icon class="mr-1">mdi-account</v-icon> {{helprequest.user.name}}
                    <v-btn v-if="!form.closed" class="ml-4 white--text" color="red darken-1" @click="form.closed = true; save();" :loading="saveProgress" :disabled="saveProgress || dangerProgress">
                        <v-icon left>mdi-close</v-icon>{{ $t('close') }}
                    </v-btn>
                    <v-btn v-else class="ml-4 white--text" color="red darken-1" @click="form.closed = false; save();" :loading="saveProgress" :disabled="saveProgress || dangerProgress">
                        <v-icon left>mdi-arrow-u-left-top</v-icon>{{ $t('open') }}
                    </v-btn>
                    <v-btn class="ml-3" color="primary" @click="save()" :loading="saveProgress" :disabled="saveProgress || dangerProgress">
                        <v-icon left>mdi-content-save-outline</v-icon>{{ $t('save') }}
                    </v-btn>
                </template>
            </template>
        </ActionBar>

        <Card class="mt-3" :title="$t('helprequest.title')">
            <v-row>
                <v-col>
                    <v-textarea
                        :label="$t('helprequest.subject')"
                        v-model="helprequest.subject"
                        rows=1
                        auto-grow
                        hide-details
                        outlined
                        readonly
                        prepend-inner-icon="mdi-clipboard-text-outline"
                    />
                </v-col>
            </v-row>

            <v-row>
                <v-col class="col-12 pt-0">
                    <v-textarea
                        class="mt-0"
                        :label="$t('helprequest.info')"
                        v-model="helprequest.info"
                        rows=1
                        auto-grow
                        hide-details
                        outlined
                        readonly
                        prepend-inner-icon="mdi-information-outline"
                    />
                </v-col>
            </v-row>

            <v-row>
                <v-col class="col-12 col-md-6 pt-0">
                    <v-text-field
                        class="mt-0"
                        :label="helprequest.phone_label"
                        v-model="helprequest.phone"
                        outlined
                        hide-details
                        readonly
                        :disabled="!helprequest.user"
                        prepend-inner-icon="mdi-phone"
                    />
                </v-col>

                <v-col class="col-12 col-md-6 pt-0">
                    <v-text-field
                        class="mt-0"
                        :label="$t('helprequest.date')"
                        v-model="helprequest.createdAt"
                        outlined
                        hide-details
                        readonly
                        prepend-inner-icon="mdi-calendar-range"
                    />
                </v-col>
            </v-row>

            <v-row>
                <v-col class="col-12 pt-0">
                    <v-textarea
                        class="mt-0"
                        :label="$t('helprequest.form.notes')"
                        v-model="form.notes"
                        rows=3
                        auto-grow
                        :error-messages="errors.notes"
                        :hide-details="!errors.notes"
                        :disabled="saveProgress || dangerProgress"
                        @change="validate('notes')"
                        outlined
                        prepend-inner-icon="mdi-note-outline"
                    />
                </v-col>
            </v-row>
        </Card>

        <Card class="mt-3" :title="$t('helprequest.patient_title')" v-if="helprequest.patient">
            <template v-slot:headerActions v-if="user.hasPerm('patient')">
                <v-btn
                    fab
                    color= 'primary'
                    x-small
                    dark
                    depressed
                    :to="{ name: 'patient', params: { id: helprequest.patient._id, routefrom: 'helprequests+helprequest.'+form.id } }"
                    ><v-icon>mdi-cog</v-icon>
                </v-btn>
            </template>
           <PatientVisitingCard :patient="helprequest.patient" />
        </Card>

        <DangerZone
            v-if="helprequest && helprequest.closed"
            @btnClick="beforeDangerZoneAction()"
            v-model="dialogDangerItem"
            :data="[
                {
                    itemKey: 'delete',
                    itemName: $t('helprequest.danger_zone.delete'),
                    itemInfo: $t('helprequest.danger_zone.delete_info'),
                    itemProgress: (dialogDangerItem && dialogDangerItem.itemKey == 'delete' ? dangerProgress : false)
                }
            ]"
        />

        <DangerZoneConfirm
            v-if="dialogDanger"
            :title="() => {switch (dialogDangerItem.itemKey) {
                case 'delete':
                    return $t('helprequest.danger_zone.delete');
                    break;
            }}"
            :description="() => {switch (dialogDangerItem.itemKey) {
                case 'delete':
                    return $t('helprequest.danger_zone.dialog.delete_desc');
                    break;
            }}"
            :descriptionContent="() => {switch (dialogDangerItem.itemKey) {
                case 'delete':
                    return helprequest.counter;
                    break;
            }}"
            :checkText="() => helprequest.counter"
            :dependenciesData="dependenciesData" 
            :open="dialogDanger"
            :save="dialogDangerItem.itemName"
            :saveProgress="dangerDialogSaveProgress"
            @cancel="dialogDanger = false" 
            @save="dangerZoneAction(dialogDangerItem)"
        /> 

        <SaveSnackBar v-model="save_snackbar" :text="$t('save_snackbar')" />
        </template>
    </Page>
</template>

<script>
import Page from "./components/Page.vue";
import ActionBar from "./components/ActionBar.vue";
import Card from "./components/Card.vue";
import PatientVisitingCard from "./components/PatientVisitingCard.vue";
import DangerZone from "./components/DangerZone.vue";
import DangerZoneConfirm from "./dialogs/DangerZoneConfirm.vue";
import validator from "../plugins/validator";
import * as helpRequestValidation from "../api/helprequest/validation";
import SaveSnackBar from "./components/SaveSnackBar.vue";

export default {
    components: {
        ActionBar,
        Page,
        Card,
        PatientVisitingCard,
        DangerZone,
        DangerZoneConfirm,
        SaveSnackBar
    },

    async mounted() {
        this.form.id = this.$route.params.id;
        this.load();
    },

    data: () => ({
        progress: true,
        searchProgress: false,
        saveProgress: false,
        dangerProgress: false,
        dangerDialogSaveProgress: false,

        dialogDanger: false,
        dialogDangerItem: null,
        dependenciesData: null,

        dialog: false,
        helprequest: null,

        form:{
            id: null,
            notes: null,
            closed: null
        },

        errors: {
            notes: null
        },

        error: {
            type: null,
            msg: null,
            code: null
        },

        pagination: {
            total: 0,
            filter: null
        },

        updateTime: null,        
        save_snackbar: false,        
    }),
    
    computed: {
        user() {
            return this.$store.state.api.user.data;
        }
    },

    methods: {
        validate(check) {
            if(check) this.errors[check] = null;
            else Object.assign(this.$data.errors, this.$options.data(this).errors);

            return validator(
                this.form,
                helpRequestValidation.update,
                function(key, type, limit) {
                    if(check && key != check) return;
                    let text = this.$t(`form_errors.${key}.${type}`);
                    this.errors[key] = text;
                }.bind(this)
            );
        },

        async load(){
            if(this.error.type){
                this.progress = true;
                this.error = {
                    type: null,
                    msg: null,
                    code: null
                }
            }

            const res = await this.$store.dispatch("api/helprequest/get", {id: this.form.id});

            if( res.status == 200 ){

                this.helprequest = res.data;
                this.form.notes = this.helprequest.notes;
                this.form.closed = this.helprequest.closed;
                this.updateTime = this.helprequest.updateTime ? new Date(this.helprequest.updateTime) : null;
                this.helprequest.phone_label = this.helprequest.user ? this.$t("helprequest.mobile") : this.$t("helprequest.no_user");
                //if (!this.helprequest.user) this.helprequest.phone = null;
                this.helprequest.createdAt = new Date(this.helprequest.createdAt).toLocaleString([], {minute: "2-digit", hour: "2-digit", day: "2-digit", month: "2-digit", year: "numeric",});

                this.progress = false;
            
            } else {

                this.error = {
                    type: "network",
                    msg: res.data.error.code,
                    code: res.status
                }

            }

            return;
        },

        async save(){
            if (!this.validate()) return;

            this.saveProgress = true;
            await this.$store.dispatch("api/helprequest/update", this.form);
            await this.load();
            this.saveProgress = false;
            this.save_snackbar = true;
        },

        async beforeDangerZoneAction() {
            this.dangerProgress = true;
            await this.load();
            this.dependenciesData = null;
            this.dangerProgress = false;
            this.dialogDanger = true;
        },

        async dangerZoneAction(item) {
            switch (item.itemKey) {
                case "delete":
                    this.dangerDialogSaveProgress = true;
                    this.dependenciesData = null;

                    const res = await this.$store.dispatch("api/helprequest/delete", { id: this.form.id });
                    if (res.status==405) {
                        this.dependenciesData = res.data.data;
                        this.dangerDialogSaveProgress = false;
                        return;
                    } else if (res.status==200) {
                        this.$router.push({ name: 'helprequests' });
                    } else {
                        this.error = {
                            type: "network",
                            msg: res.data.error.code,
                            code: res.status
                        }
                        this.dialogDanger = false;
                        this.dangerDialogSaveProgress = false;
                    }
                    break;
            }
        }
    }
};
</script>
